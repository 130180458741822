<template>
    <Layout id="layouttttttttttt">
        <div class="row mt-2">
            <div class="col-12">
                <div class="card">
                    <div class="card-body">
                        <h1><i class="mdi mdi-account-group"
                                        style="transform: scale(1.7);"></i> Manage your Clients</h1>
                        <label style="font-weight: 350;"><i class="mdi mdi-information"></i> This page allows you to manage your clients and their personal information. </label>
                        <div v-if="addingClient">
                            <button @click="addingClient = false" type="button" class="btn btn-outline btn-info mb-3"><span
                                    class="btn-label"><i class="mdi mdi-arrow-left-bold-box-outline"
                                        style="transform: scale(1.7);"></i>
                                </span>Go back </button>
                                
                            <form @submit.prevent="preventsubmit">
                                <div class="form-row">
                                    <b-form-group label="Client Name" label-for="bankname">
                                        <b-form-input type="text" id="bankname" v-model="client['Client Name']"
                                            ></b-form-input>
                                    </b-form-group>
                                    <b-form-group class="col-md-6" label="Email" label-for="input-sm">
                                        <b-form-input type="email" id="input-sm" v-model="client.Email"
                                            ></b-form-input>
                                    </b-form-group>
                                </div>
                                <b-form-group label="Phone number" label-for="phoneNumber">
                                    <b-form-input type="number" id="phoneNumber" v-model="client['Phone Number']"
                                        ></b-form-input>
                                </b-form-group>
                                <b-form-group label="Address" label-for="inputAddress">
                                    <b-form-input type="text" id="inputAddress" aria-required="" v-model="client.Address"
                                        ></b-form-input>
                                </b-form-group>
                                <div class="mt-5 mb-5 text-center">
                                    <button type="submit" @click="handleSumbit"
                                        class="btn w-25 btn-outline-primary btn-bordered-primary">Add</button>
                                </div>
                            </form>
                        </div>
                        <div v-else>
                            <p class="text-muted font-13 mb-4"></p>
                            <div class="row mb-md-4">
                                <div class="col-sm-12 col-md-4">
                                    <div id="tickets-table_length" class="dataTables_length">
                                        <label class="d-inline-flex align-items-center">
                                            Show&nbsp;
                                            <b-form-select v-model="perPage" size="sm"
                                                :options="pageOptions"></b-form-select>&nbsp;entries
                                        </label>
                                    </div>
                                </div>
                                <div class="col-sm-12 col-md-4">
                                    <label class="d-inline-flex align-items-center">New client(s)</label>
                                    <button type="button" class="btn btn-primary ml-2" @click="addingClient = true"><i
                                            class="mdi mdi-account-plus-outline" style="transform: scale(1.4);"></i></button>
                                            

                                                
                                                <span><button class="file-upload-button btn btn-primary ml-2" @click="handleFileUpload">
                                                    <i class="mdi mdi-microsoft-excel"
                                        style="transform: scale(1.7);"></i>
                                                    </button> </span>

                                                
                                                    
                                                
                                                
                                </div>
                                <!-- Search -->
                                <div class="col-sm-12 col-md-4">
                                    <div id="tickets-table_filter" class="dataTables_filter text-md-right">
                                        <label class="d-inline-flex align-items-center">
                                            Search:
                                            <b-form-input v-model="filter" type="search" placeholder="Search..."
                                                class="form-control form-control-sm ml-2"></b-form-input>
                                        </label>
                                    </div>
                                </div>
                                <!-- End search -->
                            </div>
                            <!-- Table -->
                            <div class="table-responsive table-striped mb-0">
                                <b-table style="padding: .5rem;" :items="Clients" :fields="fields" responsive="sm"
                                    :per-page="perPage" :current-page="currentPage" :sort-by.sync="sortBy"
                                    :sort-desc.sync="sortDesc" :filter="filter" :filter-included-fields="filterOn"
                                    @filtered="onFiltered">
                                    <template v-slot:cell(editButton)="data">
                                        <b-button @click="editClient(data.item)" size="sm" variant="primary"><i style="transform: scale(1.4);" class="mdi mdi-account-edit-outline"></i></b-button>
                                        <b-modal v-model="showModal" title="Edit Client">
                                            <div v-if="selectedClient">
                                                <!-- Display client information inside the modal -->
                                                <div class="row">
                                                    
                                                    <div class="col-md-6">
                                                        <div class="form-group">
                                                            <label for="field-1" class="control-label">Client Name</label>
                                                            <input type="text" v-model="selectedClient['Client Name']" class="form-control" />
                                                        </div>
                                                        
                                                    </div>
                                                    <div class="col-md-6">
                                                        <div class="form-group">
                                                            <label for="field-2" class="control-label">Email</label>
                                                            <input type="email" v-model="selectedClient.Email" class="form-control" />
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="row">
                                                    <div class="col-md-12">
                                                        <div class="form-group">
                                                            <label for="field-3" class="control-label">Phone Number</label>
                                                            <input type="numer" v-model="selectedClient['Phone Number']" class="form-control" />
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="row">
                                                    <div class="col-md-12">
                                                        <div class="form-group">
                                                            <label for="field-4" class="control-label">Address</label>
                                                            <input type="text" v-model="selectedClient.Address" class="form-control" />
                                                        </div>
                                                    </div>
                                                </div>
                                                <!-- Add more fields here as needed -->
                                            </div>

                                            <template #modal-footer="{ cancel, ok }">
                                                <!-- Modal buttons -->
                                                <b-button @click="cancel">Cancel</b-button>
                                                <b-button @click="saveChanges" variant="primary">Save</b-button>
                                            </template>
                                        </b-modal>
                                    </template>
                                </b-table>
                            </div>
                            <div class="row">
                                <div class="col">
                                    <div class="dataTables_paginate paging_simple_numbers float-right">
                                        <ul class="pagination pagination-rounded mb-0">
                                            <!-- pagination -->
                                            <b-pagination v-model="currentPage" :total-rows="rows"
                                                :per-page="perPage"></b-pagination>
                                        </ul>
                                    </div>
                                </div>
                            </div>


                        </div>
                    </div>
                </div>
            </div>
        </div>
    </Layout>
</template>

<script>

import Layout from "./layouts/main";
import axios from "axios"
import swal from "sweetalert"
import { read, utils, write } from 'xlsx';

export default {
    data() {
        return {
            data: [],
            fileUploaded: false,
            dataLabels: [],
            addingClient: false,
            Clients: [],
            client: {
                Societe: localStorage.getItem("societe").slice(1, -1),
                "Client Name": "",
                Email: "",
                "Phone Number": "",
                Address: ""
            },


            totalRows: 1,
            currentPage: 1,
            perPage: 10,
            pageOptions: [10, 25, 50, 100],
            filter: null,
            filterOn: [],
            sortBy: "",
            sortDesc: false,
            fields: [
                {
                    key: "Client Name",
                    sortable: true,
                },
                {
                    key: "Email",
                    sortable: true,
                },
                {
                    key: "Phone Number",
                    sortable: true,
                },
                {
                    key: "Address",
                    sortable: true,
                },
                {
                    key: 'editButton', label: '',
                    tdClass: 'edit-column'
                }
            ],
            selectedClient: null,
            showModal: false
        }
    },
    components: {
        Layout,
    },
    computed: {
        rows() {
            return this.Clients.length;
        },
    },
    async mounted() {
        await this.getClients()
    },
    methods: {
        async handleFileUpload() {
            const input = document.createElement("input");
            input.type = "file";
            input.accept = ".xlsx, .xls";
            input.style.display = "none";
            input.addEventListener("change", async (event) => {
                const selectedFile = event.target.files[0];
                if (selectedFile) {
                    const file = selectedFile;
                    if (file) {
                        this.fileUploaded = true
                        const readFileAsync = (file) => {
                            return new Promise((resolve, reject) => {
                                const reader = new FileReader()
                                reader.onload = (e) => resolve(e.target.result)
                                reader.onerror = (e) => reject(e)
                                reader.readAsBinaryString(file)
                            });
                        };

                        try {
                            const data = await readFileAsync(file)
                            const workbook = read(data, { type: 'binary' })
                            const sheetName = workbook.SheetNames[0]
                            const sheet = workbook.Sheets[sheetName]
                            this.data = utils.sheet_to_json(sheet, { header: 1 })
                            //this.dataLabels = this.data[0]
                            this.dataLabels = this.data[0].map(column => column.trim())
                            this.data[0].push('Societe');
                            const dataModel = ['Client Name', 'Email', 'Phone Number', 'Address']
                            if (JSON.stringify(this.dataLabels) !== JSON.stringify(dataModel)) {
                                swal({
                                    title: 'Error',
                                    text: `Fields missmatch, please use our template.`,
                                    icon: 'error',
                                })
                                this.data = []
                                return
                            }
                            this.data.shift()
                            console.log(this.data)
                            this.data.forEach(async (row, index) => {
                                let newClient= {}
                                newClient["Societe"]= localStorage.getItem("societe").slice(1, -1)
                                newClient["Client Name"] = row[0]
                                newClient["Email"] = row[1]
                                newClient["Phone Number"] = row[2]
                                newClient["Address"] = row[3]
                                try {
                                        await axios.post('https://finex.4help.tn/api/v1/addclient', newClient).then(async (res) => {
                                            if (res.status === 200) {
                                                swal({
                                                    title: 'Success',
                                                    text: `Successfully added new client: ${newClient["Client Name"]} !`,
                                                    icon: 'success',
                                                });
                                                await this.getClients()
                                                this.addingClient = false
                                                this.currentPage = 1
                                                this.client = {
                                                    "Client Name": "",
                                                    Email: "",
                                                    "Phone Number": "",
                                                    Address: ""
                                                }

                                            }
                                        })
                                    } catch (error) {
                                        if (error.response && error.response.status === 409) {
                                            swal({
                                                title: 'Conflict',
                                                text: `Client already exists, please try again.`,
                                                icon: 'error',
                                            });
                                        }
                                        else {
                                            swal({
                                                title: 'Error',
                                                text: `Oops! something went wrong.`,
                                                icon: 'error',
                                            });
                                        }
                                    }
                            })
                            this.initselectedIndexes(this.data.length)
                        } catch (error) {
                            console.error(error)
                            // Handle errors here
                        }
                    }
                }
            });

            input.click();

        },
        saveChanges() {
            // Implement your logic to save the changes made in the modal
            // For example, you can update the client data in your Clients array

            // Find the index of the selectedClient in the Clients array
            const index = this.Clients.findIndex(client => client._id === this.selectedClient._id);
            console.log(this.selectedClient)
            // Update the client data with the changes made in the modal
            axios.post('https://finex.4help.tn/api/v1/updateclient', { oldClient: this.Clients[index], newClient: this.selectedClient }).then(res => {
                if (res.status != 200) {
                    swal({
                        title: 'Error',
                        text: `Oops! something went wrong.`,
                        icon: 'error',
                    });
                } else {
                    this.$set(this.Clients, index, this.selectedClient)
                    // Close the modal
                    this.showModal = false;
                }
            })
        },
        editClient(client) {
            // Implement your logic for editing the client here
            this.selectedClient = { ...client }; // Clone the client object to avoid modifying the original data
            this.showModal = true; // Show the modal
        },
        preventsubmit(e) {
            e.preventDefault()
        },
        async handleSumbit() {
            try {
                await axios.post('https://finex.4help.tn/api/v1/addclient', this.client).then(async (res) => {
                    if (res.status === 200) {
                        swal({
                            title: 'Success',
                            text: `Successfully added new client: ${this.client["Client Name"]} !`,
                            icon: 'success',
                        });
                        await this.getClients()
                        this.addingClient = false
                        this.currentPage = 1
                        this.client = {
                            "Client Name": "",
                            Email: "",
                            "Phone Number": "",
                            Address: ""
                        }

                    }
                })
            } catch (error) {
                if (error.response && error.response.status === 409) {
                    swal({
                        title: 'Conflict',
                        text: `Client already exists, please try again.`,
                        icon: 'error',
                    });
                }
                else {
                    swal({
                        title: 'Error',
                        text: `Oops! something went wrong.`,
                        icon: 'error',
                    });
                }
            }
        },
        onFiltered(filteredItems) {
            this.totalRows = filteredItems.length;
            this.currentPage = 1;
        },
        async getClients() {
            this.Clients = await axios.get(`https://finex.4help.tn/api/v1/getclients/${localStorage.getItem("societe").slice(1, -1)}`).then(res => { return res.data })
        },
    }
}

</script>

<style scoped>
h1 {
    font-size: 28px;
    margin-bottom: 20px;
}
</style>